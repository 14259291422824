<nav class="navbar px-2 px-sm-5">
  <span class="navbar-brand mb-0 cursor-pointer" [routerLink]='["/home"]'>
    <img [src]="logo_brand" alt="realinver-realidades-de-inversion">
  </span>
  <div class="d-flex align-items-center color-blue" *ngIf="has_session">
    <h3 class="m-0 d-none d-sm-block">¡HOLA, {{name_user.substring(0, 10)}}{{name_user.length > 10 ? '...' : ''}}!</h3>
    
    <span class="container-avatar-default-navbar ms-4 dropend" type="button" id="dropdown-navbar" data-bs-toggle="dropdown" aria-expanded="false">
      <img [src]="avatar_user" />
    </span>
    <div class="dropdown">
      <ul class="dropdown-menu" aria-labelledby="dropdown-navbar">
        <li class="cursor-pointer"><a class="dropdown-item" (click)="logoutAction()">Cerrar sesión</a></li>
        <li class="cursor-pointer"><a class="dropdown-item" [routerLink]='["/configuracion"]'>Configuración</a></li>
      </ul>
    </div>
  </div>
</nav>
<app-breadcrumb></app-breadcrumb>