<section class="policie-detail">
  <div class="row w-100 m-0 policy-name-container">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h3>Póliza {{policy?.folio}}</h3>
      <div class="d-flex align-items-center">{{policy?.status}}
        <span [ngClass]="getColorStatus(policy?.status)" class="ms-2 dot"></span>
      </div>
    </div>
  </div>

  <div class="container-form">

    <div class="row w-100 m-0">
      <div class="w-100 mb-4 col-12">
        <span class="me-3 d-block mb-2">Beneficiario</span>
        <div class="px-4">
          <input type="text" disabled class="form-control" placeholder="Beneficiario" [ngModel]="policy?.user_name">
        </div>
      </div>
    </div>
  
    <div class="w-100 row mb-4 m-0">
      <div class="col-12 col-md-6 mb-4">
        <span class="me-3 d-block mb-2">Fecha de inicio</span>
        <div class="px-4">
          <input type="text"
            placeholder="Datepicker"
            class="form-control"
            bsDatepicker
            disabled
            [ngModel]="policy?.created_at"
          >
        </div>
      </div>
  
      <div class="col-12 col-md-6 mb-4">
        <span class="me-3 d-block mb-2">Fecha de vencimiento</span>
        <div class="px-4">
          <input type="text"
            placeholder="Datepicker"
            class="form-control"
            bsDatepicker
            disabled
            [ngModel]="policy?.expired_at"
          >
        </div>
        </div>
    </div>
    <h4 class="mb-4 px-2 title-documents">Documentos Asociados</h4>
  
    <div class="w-100 row mb-4 m-0">
      <div class="col-12 col-md-6">
        <span class="me-3 d-block mb-4">Formato firmado</span>
          <div class="px-4 mb-4">
            <div class="form-control">
              <a class="d-flex justify-content-between align-items-center a-links" [href]="policy?.contract" target="_blank" *ngIf="policy?.contract;else else_contract">
                Ver documento
                <img src="./../../../assets/img/icons/clip.png" class="img-blue-clip">
              </a>

              <ng-template #else_contract>
                No se ha subido ningún formato firmado
              </ng-template>
            </div>
          </div>
      </div>
  
      <div class="col-12 col-md-6">
        <span class="me-3 d-block mb-4">Identificación</span>
        <div class="px-4 mb-4">
          <div class="form-control">
            <a class="d-flex justify-content-between align-items-center a-links" [href]="policy?.identification" target="_blank" *ngIf="policy?.identification;else else_identification">
              Ver documento
              <img src="./../../../assets/img/icons/clip.png" alt="" class="img-blue-clip">
            </a>
            <ng-template #else_identification>No se ha subido ninguna identificación</ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button type="submit" class="btn btn-success mt-3" (click)="dowloadCertification()">Descargar certificado</button>
    </div>
  </div>
</section>