<section class="landing">
  <div class="row m-0">
    <div class="col-lg-8 mx-auto">
      <div class="mt-3 section-home row">
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 menu-item-container mb-2 mb-sm-0"
          *ngFor="let item of main_routes"
          [ngClass]="{'d-none': item.hide}"
        >
          <div class="menu-item" [routerLink]="item.route">
            <div class="menu-icon">
              <img src="/assets/img/icons/{{ item.icon }}" alt="" [ngClass]="item.class"/>
            </div>
            <div class="menu-text">
              <div class="border-circle"></div>
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>