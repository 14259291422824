<ng-template #modalLogin >
  <div class="custom-modal modal-body">
    <div class="modal-header">
      <h4 class="modal-title text-center w-100">Inicio de sesión</h4>
    </div>
    <form class="px-4 py-3" (ngSubmit)="loginAction()" [formGroup]="form_login" *ngIf="!forgot_password_view">
      <div class="mb-3">
        <label for="exampleDropdownFormEmail1" class="form-label">Nombre de usuario</label>
        <input formControlName="email" type="email" class="form-control" id="exampleDropdownFormEmail1" placeholder="email@example.com">
      </div>
      <div class="mb-3">
        <label for="exampleDropdownFormPassword1" class="form-label">Contraseña</label>
        <input formControlName="password" type="password" class="form-control" id="exampleDropdownFormPassword1" placeholder="Password">
      </div>
      <div class="d-flex justify-content-center flex-wrap">
        <button type="submit" class="btn btn-primary btn-rounded" [disabled]="form_login.invalid">Iniciar Sesión</button>
        <strong class="w-100 text-center">

          <a class="d-block mt-3 cursor-pointer forgot-password" (click)="forgot_password_view = true">¿Olvidaste la contraseña?</a>
        </strong>
      </div>
    </form>
    
    <form class="px-4 py-3" (ngSubmit)="forgotPasswordAction()" [formGroup]="form_login" *ngIf="forgot_password_view && !password_recover_view">
      <div class="mb-3">
        <h4>Recupera tu cuenta</h4>
        <p>	Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</p>
        <label for="exampleDropdownFormEmail1" class="form-label">Email address</label>
        <input formControlName="email" type="email" class="form-control" id="exampleDropdownFormEmail1" placeholder="email@example.com">
      </div>
      <div class="d-flex justify-content-center flex-wrap">
        <button type="submit" class="btn btn-primary btn-rounded" [disabled]="form_login.invalid">Enviar link de recuperación</button>
        <strong class="w-100 text-center">
          <a class="mt-3 d-block cursor-pointer forgot-password" (click)="forgot_password_view = false">< Regresar</a>
        </strong>
      </div>
      
    </form>

    <div *ngIf="password_recover_view">
      <h4>Recupera tu cuenta</h4>
      <p>Se ha enviado un email a la cuenta <strong>{{form_login.value.email}}</strong>, con el link para recuperar tu cuenta.</p>
      <a class="mt-3 d-block cursor-pointer forgot-password" (click)="forgot_password_view = false; password_recover_view = false;">< Regresar</a>
    </div>
  </div>
</ng-template>