
<section id="main-container">

  <header>
    <app-navbar *ngIf="has_session"></app-navbar>
  </header>
  
  <section class="p-2">
    <router-outlet></router-outlet>
  </section>
</section>