<section class="d-flex justify-content-center flex-wrap">
  <div class="section-home mt-5 w-100">
    <div class="row w-100 m-0 badge-blue">
      <div class="w-100 d-flex justify-content-between align-items-center">
      </div>
    </div>

    <form class="section-gray-coursers p-5 w-100"  (ngSubmit)="OnSubmit()" [formGroup]="form_settings">
      <div class="row">
        <div class="col-12 col-md-6 mb-2 mb-sm-0">
          <div class="margin-negative-for-avatar">
            <div class="main-avatar-user-container" (click)="addAvatar()">
              <img [src]="user?.avatar" alt="" [ngClass]="getUserClassAvatar()">
            </div>
          </div>
        </div>
        <input type="file" class="d-none" accept=".jpg, .png, .jpeg" id="input-file-avatar-settings" (change)="onFileSelected($event)" formControlName="avatar">
        <div class="col-12 col-md-6">
          <span class="me-3 d-block mb-2">Apellido Paterno</span>
          <div class="px-4 mb-4">
            <input type="text" class="form-control" placeholder="Apellido Paterno" [ngModel]="user?.last_name" formControlName="last_name">
          </div>
  
          <div class="mb-4">
            <span class="me-3 d-block mb-2">Apellido Materno</span>
            <div class="px-4"><input type="text" class="form-control" placeholder="Apellido Materno" [ngModel]="user?.second_last_name" formControlName="second_last_name">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <span class="me-3 d-block mb-2">Nombre(s)</span>
          <div class="px-4"><input type="text" class="form-control" placeholder="Nombres" [ngModel]="user?.names" formControlName="names"></div>
        </div>
  
        <div class="col-12 col-md-6">
          <span class="me-3 d-block mb-2">Correo Electrónico</span>
          <div class="px-4"><input type="text" class="form-control" placeholder="Correo Electrónico" [ngModel]="user?.email" formControlName="email"></div>
        </div>
      </div>
  
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-success mt-3" [disabled]="form_settings.invalid">Guardar</button>
      </div>
    </form>
  </div>
</section>