<form class="px-4 py-3" (ngSubmit)="recoverPassword()" [formGroup]="form_recover" *ngIf="!succed_recover; else block_else">
  <div class="mb-3">
    <label for="exampleDropdownFormEmail1" class="form-label">Nueva contraseña</label>
    <input formControlName="password" type="password" class="form-control">
  </div>
  <div class="mb-3">
    <label for="exampleDropdownFormPassword1" class="form-label">Confirmar Nueva Contraseña</label>
    <input formControlName="confirm_password" type="password" class="form-control">
  </div>
  <div>

    <button 
      type="submit" 
      class="btn btn-primary" 
      [disabled]="(form_recover.value.password !== form_recover.value.confirm_password) || !form_recover.value.password || !form_recover.value.confirm_password">Reestablecer Contraseña</button>
  </div>
</form>

<ng-template #block_else>
  <div class="d-flex align-items-center justify-content-center container-recover flex-wrap">
    <div>

      <h1 class="w-100 text-center">Tu contraseña ha sido cambiada exitosamente</h1>
      <a [routerLink]="['/login']" class="text-center d-block">Ir a login</a>
    </div>
  </div>
</ng-template>